.Product{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Product .services_product{
    gap: 20px;
}
.Product .services_product .title_page{
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 40px;
}
.Product .services_product .div_list_data{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
.Product .services_product .div_list_data .div_service_or_product{
    width: 270px;
    min-width: 270px;
    height: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.Product .services_product .div_list_data .div_service_or_product .div_img{
    width: 100%;
    height: 180px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Product .services_product .div_list_data .div_service_or_product .title{
    font-weight: bold;
    width: 100%;
    text-align: center;
}
.Product .services_product .div_list_data .div_service_or_product .subtitle{
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
}
.Product .services_product .div_list_data .div_service_or_product .div_price .bold{
    font-weight: bold;
}
.Product .services_product .div_list_data .div_service_or_product .div_price .underline{
    text-decoration: line-through;
}
.Product .services_product .div_list_data .div_service_or_product .div_price .discount_price{
    font-weight: bold;
    font-size: 20px;
}
.Product .services_product .div_list_data .div_service_or_product .btn{
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid;
    cursor: pointer;
}
.Product .services_product .div_list_data .div_service_or_product .div_purchase{
    color: #ffffff;
    cursor: pointer;
}
.Product .services_product .div_list_data .div_service_or_product .div_purchase .icons{
    width: 18px;
    height: auto;
}

.Product .services_product .show_add_show_product{
    font-size: 16px;
    text-align: right;
    cursor: pointer;
}

@media only screen and (max-width: 1100px) {
    .Product .services_product .div_list_data .div_service_or_product .div_price .discount_price{
        font-size: 16px;
    }
}
@media only screen and (max-width: 648px) {
    .Product .services_product .div_list_data{
        justify-content: center;
    }
    .Product .services_product .div_list_data .div_service_or_product{
        width: 100%;
        min-width: 100%;
        height: auto;
        min-height: inherit;
    }
    .Product .services_product .div_list_data .div_service_or_product .div_img{
        height: 260px;
    }
    .Product .services_product .div_list_data .div_service_or_product:nth-child(n + 1){
        border-bottom: 1px solid #919191;
        padding-bottom: 30px;
    }
    .Product .services_product .div_list_data .div_service_or_product:nth-last-child(-n + 1){
        border-bottom: none;
    }
}