.Brands{    
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Brands .title_page{
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 40px;
}
.Brands .div_list_data .slick-slider{
    width: 100%;
    margin: 0% auto;
}
.Brands .div_list_data .slick-prev{
    left: 0;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent !important;
}
.Brands .div_list_data .slick-list{
    width: 90%;
    margin: 0% auto;
}
.Brands .div_list_data .slick-next{
    right: 0;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent !important;
}
.Brands .div_list_data .slick-prev:before, .Brands .div_list_data .slick-next:before{
    font-size: 30px;
    color: initial;
}
.Brands .div_list_data .show_brands{
    width: 214px !important;
    height: 120px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Brands .div_list_data .show_brands .img_brands{
    max-width: 75%;
    max-height: 110px;
}

@media only screen and (max-width: 630px) {
}
@media only screen and (max-width: 534px) {
    .Brands .div_list_data .show_brands{
        width: 90% !important;
        margin: 0 auto;
    }
}