.User{
    width: 100%;
    height: auto;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    border-top: 1px solid rgb(241 241 241);
    border-bottom: 1px solid rgb(241 241 241);
}
.User .show_data_user{
    width: calc(100% - 60px);
    padding: 30px;
    border-radius: 6px;
    border: 1px solid rgb(241 241 241);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.User .show_data_user .div_input{ 
    width: 100%;
    margin-top: 10px; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    position: relative;
}
.User .show_data_user .div_input .two_input{
    width: auto;
    position: relative;
}
.User .show_data_user .div_input input{      
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.User .show_data_user .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 10px;
    background: #ffffff;
    padding: 0px 10px;
}
.User .show_data_user .div_input .cep{
    width: 110px;
}
.User .show_data_user .div_input .cep input{
    text-align: center;
}
.User .show_data_user .div_input .uf{
    width: 60px;
}
.User .show_data_user .div_input .uf input{
    text-align: center;
}
.User .show_data_user .div_input .password{
    width: 200px;
}
.User .show_data_user .div_input .contact{
    width: 160px;
}

.User .show_data_user .div_show_data_user{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid rgb(241 241 241);
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.User .show_data_user .div_show_data_user div{
    width: auto;
    height: 36px;
    padding: 0px 20px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.User .show_data_user .div_show_data_user .logout{
    background-color: #d95c5c;
}

.User .show_data_user .btn{
    color: #ffffff;
    width: 100%;
    border: none;
    padding: 10px;
    font-family: 'inter';
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
}
.User .show_data_user .error{
    color: #d95c5c;
    text-align: center;
}

.User .show_data_user .div_purchase{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(241 241 241);
}
.User .show_data_user .div_purchase:nth-last-child(-n + 1){
    border: none;
    padding-bottom: 0px;
}
.User .show_data_user .div_purchase .div_date{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.User .show_data_user .div_purchase .div_btn{    
    background-color: #76c57e;
    color: #ffffff;
    padding: 6px 20px;
    font-family: 'inter';
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
}

@media only screen and (max-width: 540px) {
    .User .show_data_user{
        padding: 30px 10px;
        width: calc(100% - 20px);
    }
    .User .show_data_user .div_show_data_user{
        flex-direction: column;
    }
    .User .show_data_user .div_show_data_user div{
        width: calc(100% - 40px);
    }
}
@media only screen and (max-width: 480px) {
    .User .show_data_user .div_purchase{
        flex-direction: column;
    }
    .User .show_data_user .div_purchase .div_date{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .User .show_data_user .div_purchase .div_btn{
        width: calc(100% - 40px);
        text-align: center;
    }
}