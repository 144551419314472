.Purchase{
    width: 100%;
    height: auto;
    display: flex;
    padding: 40px 0px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgb(241 241 241);
    border-bottom: 1px solid rgb(241 241 241);
}
.Purchase .container{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
}
.Purchase .list_purchase{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}
.Purchase .list_purchase .title{
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    width: calc(100% - 40px);
    height: 62px;
    font-weight: bold;
    padding: 0px 20px;
}
.Purchase .list_purchase .title .qtd{
    font-weight: normal;
}
.Purchase .list_purchase .div_list{
    width: 100%;
}
.Purchase .list_purchase .div_list .inf_product{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid rgb(241 241 241);
    padding-bottom: 40px;
}
.Purchase .list_purchase .div_list .inf_product:nth-child(n + 2){
    padding-top: 40px;
}
.Purchase .list_purchase .div_list .inf_product:nth-last-child(-n + 1){
    border: none;
    padding-bottom: 0px;
}
.Purchase .list_purchase .div_list .inf_product .div_img{
    width: 160px;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Purchase .list_purchase .div_list .inf_product .details_product{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.Purchase .list_purchase .div_list .inf_product .details_product .title_product{
    font-size: 22px;
    font-weight: 900;
    line-height: 22px;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input .discount_price{
    text-decoration: line-through;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input .qtd{
    width: 30px;
    text-align: center;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input .price_input{
    width: 80px;
    text-align: center;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input input{
    width: 94px;
    text-align: center;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    background-color: #ffffff;
}
.Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve .btn_remuve{
    border: 2px solid #d95c5c;
    background: #d95c5c;
    color: #ffffff;
    position: relative;
    padding: 0px 20px;
    height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
    border-radius: 6px;
    cursor: pointer;
}

.Purchase .div_price{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    width: 300px;
    min-width: 300px;
}
.Purchase .div_price .title{
    height: 62px;
    font-size: 22px;
    font-weight: 900;
    line-height: 22px;
    
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    width: calc(100% - 40px);
    height: 62px;
    font-weight: bold;
    padding: 0px 20px;
}
.Purchase .div_price .subtitle{
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #808080;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Purchase .div_price .span{
    font-weight: bold;
}
.Purchase .div_price .div_show_price{
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #F5F5F5;
    padding: 10px 20px;
}
.Purchase .div_price .div_show_price .div_inf_price{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}
.Purchase .div_price .div_show_price .price_x{
    font-weight: normal;
    font-size: 12px;
}
.Purchase .div_price .btn_finished{
    border: 1px solid;
    color: #ffffff;
    width: calc(100% - 42px);
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 1150px) {
    .Purchase .container{
        gap: 20px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase{
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve{
        width: 100%;
        min-width: 100%;
        margin-top: 10px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve .btn_remuve{
        margin-top: 0;
    }
}
@media only screen and (max-width: 920px) {
    .Purchase .div_price .title{
        font-size: 16px;
        height: 46px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .title_product{
        font-size: 16px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .subtitle_product,
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input,
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_input input{
        font-size: 12px;
    }
    .Purchase .div_price .btn_finished{
        padding: 6px 20px;
    }
    .Purchase .container{
        flex-direction: column;
    }
    .Purchase .list_purchase{
        width: 100%;
    }
    .Purchase .div_price{
        width: 100%;
        border-top: 1px solid;
        padding-top: 30px;
        margin-top: 10px;
    }
    .Purchase .list_purchase .div_list .inf_product .div_img{
        width: 240px;
        height: 240px;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve{
        width: auto;
        min-width: auto;
        margin-top: 22px;
    }
}
@media only screen and (max-width: 760px) {
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve{
        width: 100%;
        min-width: 100%;
        margin-top: 10px;
    }
}
@media only screen and (max-width: 550px) {
    .Purchase .list_purchase .div_list .inf_product{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product,
    .Purchase .list_purchase .div_list .inf_product .details_product .title_product,
    .Purchase .list_purchase .div_list .inf_product .details_product .subtitle_product,
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase{
        width: 100%;
    }
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve{
        width: auto;
        min-width: auto;
        margin-top: 22px;
    }
}
@media only screen and (max-width: 470px) {
    .Purchase .list_purchase .div_list .inf_product .details_product .div_inf_purchase .div_remuve{
        width: 100%;
        min-width: 100%;
        margin-top: 10px;
    }
}