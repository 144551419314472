.Toast{
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 9999;
    background: #4de775;
    color: #ffffff;
    opacity: 0.8;
    padding: 10px 20px;
    transform: translateX(-50%);
    cursor: default;
}