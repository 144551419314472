.Category{    
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Category .title_page{
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 40px;
}
.Category .div_list_data .slick-slider{
    width: 100%;
    margin: 0% auto;
}
.Category .div_list_data .slick-prev{
    left: 0;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent !important;
}
.Category .div_list_data .slick-list{
    width: 94%;
    margin: 0% auto;
}
.Category .div_list_data .slick-next{
    right: 0;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent !important;
}
.Category .div_list_data .slick-prev:before, .Category .div_list_data .slick-next:before{
    font-size: 30px;
    color: initial;
}
.Category .div_list_data .show_category{
    width: 214px !important;
    height: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Category .div_list_data .show_category .img_category{
    max-width: 96%;
    max-height: 96%;
}
.Category .div_list_data .show_category .comp_title{
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
}

@media only screen and (max-width: 800px) {
    .Category .div_list_data .slick-list{
        width: 98%;
    }
}