.PopUp .Confirmation{
    width: 400px;
}
.PopUp .Confirmation .div_topic{
    justify-content: center;
}
.PopUp .Confirmation .div_content .opt{
    text-align: center;
}
.PopUp .Confirmation .btn{
    color: #ffffff;
    flex-grow: 1;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 6px 10px;
    text-transform: uppercase;
}
.PopUp .Confirmation .yes{
    background-color: #76c57e;
}
.PopUp .Confirmation .not{
    background-color: #e95656;
}