.SlideShow {
    width: 100%;
    height: 528px;
}

.SlideShow .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 528px;
}
.SlideShow .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
.SlideShow .react-slideshow-container .nav:first-of-type{
    left: 60px;
    /* background: #6b7d5c; */
    fill: #ffffff;
}
.SlideShow .react-slideshow-container .nav:last-of-type{
    right: 60px;
    /* background: #6b7d5c; */
    fill: #ffffff;
}
.SlideShow .show_img_slide_show{
    background-position: center;
    background-repeat: no-repeat;
}