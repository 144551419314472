footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}
footer .div_show_data_company{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0px;
}
footer .div_show_data_company .div_opt{
    font-family: 'Inter';
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    gap: 30px;
    padding: 0px 20px;
}
footer .div_show_data_company .div_opt .title{
    width: 100%;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
}
footer .div_show_data_company .div_opt .show_data{
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
footer .div_show_data_company .div_opt .show_data div{
    cursor: pointer;
}
footer .div_show_data_company .div_opt .show_data .div_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
footer .div_show_data_company .div_opt .show_data .div_icon a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
footer .div_show_data_company .div_opt .show_data .div_icon .icons{
    width: 25px;
    height: auto;
}
footer .div_show_data_company .div_opt .show_data .div_logo{
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}
footer .div_show_data_company .div_opt .show_data .div_logo img{
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
footer .div_show_data_company .div_opt .show_data .title{
    padding-top: 30px;
}
footer .div_show_data_company .div_opt .show_data .list_icons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 20px;
}
footer .div_show_data_company .div_opt .show_data .list_icons div{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
footer .div_show_data_company .div_opt .show_data .list_icons div .icons{
    width: 20px;
    height: auto;
}

footer .font_text{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #656464;
}
footer .div_inf{
    width: calc(100% - 32px);
    padding: 16px;
    background: #CACACA;
    width: calc(100% - 32px);
    padding: 16px;
    background: #CACACA;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
footer .div_inf div{
    cursor: pointer;
}

footer .div_right_reserved{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 0px;
}
@media only screen and (max-width: 1030px) {
    footer .div_show_data_company{
        flex-direction: column;
        gap: 20px;
    }
    footer .div_show_data_company .div_opt{
        width: calc(100% - 40px);
    }
    footer .div_show_data_company .div_opt .title{
        text-align: center;
        font-size: 14px;
    }
    footer .div_show_data_company .div_opt .show_data{
        align-items: center;
        font-size: 10px;
    }
    footer .font_text{
        font-size: 10px;
    }
}
@media only screen and (max-width: 660px) {
    footer .div_inf{
        flex-direction: column;
    }
    footer .div_inf .show_bar{
        display: none;
    }
}
@media only screen and (max-width: 660px) {
    footer .div_show_data_company .div_opt{
        min-width: inherit;
    }
}