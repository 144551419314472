.PopUp .DetailsPurchase{
    width: 530px;
}
.PopUp .DetailsPurchase .div_content{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .DetailsPurchase .div_content .div_inf_purchase{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.PopUp .DetailsPurchase .div_list{
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgb(241 241 241);
}
.PopUp .DetailsPurchase .div_content .inf_product{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid rgb(241 241 241);
    padding-bottom: 20px;
}
.PopUp .DetailsPurchase .div_content .inf_product:nth-child(n + 2){
    padding-top: 20px;
}
.PopUp .DetailsPurchase .div_content .inf_product:nth-last-child(-n + 1){
    border: none;
    padding-bottom: 0px;
}
.PopUp .DetailsPurchase .div_content .inf_product .div_img{
    width: 160px;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .title_product{
    font-size: 22px;
    font-weight: 900;
    line-height: 22px;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_input{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_input .discount_price{
    text-decoration: line-through;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_input .qtd{
    width: 30px;
    text-align: center;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_input .price_input{
    width: 80px;
    text-align: center;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_input input{
    width: 94px;
    text-align: center;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    background-color: #ffffff;
    cursor: default;
}
.PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_remuve .btn_remuve{
    border: 2px solid #d95c5c;
    background: #d95c5c;
    color: #ffffff;
    position: relative;
    padding: 0px 20px;
    height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
    border-radius: 6px;
    cursor: pointer;
}

@media only screen and (max-width: 580px) {
    .PopUp .DetailsPurchase .div_content .inf_product{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .PopUp .DetailsPurchase .div_content .inf_product .details_product,
    .PopUp .DetailsPurchase .div_content .inf_product .details_product .title_product,
    .PopUp .DetailsPurchase .div_content .inf_product .details_product .subtitle_product,
    .PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase{
        width: 100%;
        text-align: center;
    }
    .PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_remuve{
        width: auto;
        min-width: auto;
        margin-top: 22px;
    }
}
@media only screen and (max-width: 470px) {
    .PopUp .DetailsPurchase .div_content .inf_product .details_product .div_inf_purchase .div_remuve{
        width: 100%;
        min-width: 100%;
        margin-top: 10px;
    }
}