header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
header .show_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px
}

header .div_submenu{
    width: 100%;
    height: 50px;
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
header .div_submenu .show_data div{
    cursor: pointer;
    text-align: center;
}
header .div_submenu .show_data .div_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 6px
}
header .div_submenu .show_data .div_icons .width_icons{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
header .div_submenu .show_data .div_icons .width_icons .icons{
    width: auto;
    height: 24px;
}
header .div_submenu .show_data .div_icons .width_icons .icons_facebook{
    height: 20px;
}

header .div_menu{
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
header .div_menu .show_data{
    justify-content: center;
}
header .div_menu .div_logo{
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
header .div_menu .div_logo img{
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
header .div_menu .div_search{
    flex-grow: 1;
    margin: 0px 60px;
    position: relative;
}
header .div_menu .div_search input{
    font-family: 'inter';
    outline: none;
    padding: 10px 46px 10px 10px;
    width: -webkit-fill-available;
}
header .div_menu .div_search .icons{
    width: 28px;
    height: auto;
    position: absolute;
    right: 10px;
    top: 6px;
}
header .div_menu .div_inf_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
header .div_menu .div_purchase{
    position: relative;
}
header .div_menu .div_purchase .qtd{
    background: #f00000;
    width: 16px;
    height: 16px;
    color: #ffffff;
    font-family: 'Roboto';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    right: -5px;
}
header .div_menu .div_purchase .icons{
    width: 31px;
    height: auto;    
}
header .div_menu .div_purchase .show_purchase {
    position: absolute;
    width: 310px;
    height: auto;
    left: -130px;
    top: 50px;
    border: 1px solid;
    padding: 20px;
    z-index: 9999;
    background: #ffffff;
    box-shadow: #000 1px 1px 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 14px;
}
header .div_menu .div_purchase .show_purchase .div_inf_product{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .subtitle_product{
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .img_product{
    width: 80px;
    height: 120px;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .preview_inf{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .preview_inf .inf_product{
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .preview_inf .inf_product .div_price{
    font-size: 10px;
    line-height: 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .preview_inf .inf_product .div_price div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
header .div_menu .div_purchase .show_purchase .div_inf_product .preview_inf .inf_product .discount_price{
    text-decoration: line-through;
}
header .div_menu .div_purchase .show_purchase .div_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #ffffff;
    padding: 10px 0px;
    cursor: pointer;
}
header .div_menu .div_purchase .show_purchase .div_btn .icons{
    width: 16px;
    height: auto;
}
header .div_menu .div_purchase .show_purchase .no_data{
    width: 100%;
    text-align: center;
}

header .div_menu .div_img_user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
header .div_menu .div_img_user .icons_{
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
}
header .div_menu .div_img_user .icons{
    width: 45px;
    height: auto;
}
header .div_menu .div_img_user .div_login_or_register .title{
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    cursor: pointer;
}
header .div_menu .mobile{
    display: none;
}

header .div_menu .div_search_mobile{
    display: none;
}

.close_purchase{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8888;
}

@media only screen and (max-width: 900px) {
    header .div_menu .div_search{
        margin: 0px 30px;
    }
    header .div_menu .mobile{
        display: block;
        position: relative;
    }
    header .div_menu .mobile .show_menu{
        width: 400px;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0px;
        background: #000000;
        z-index: 999;

        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    header .div_menu .mobile .menu_active{
        display: flex;
        border-left: 1px solid #ffffff;
    }
    header .div_menu .mobile .div_close_menu{
        width: calc(100% - 40px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 14px 20px;
    }
    header .div_menu .mobile .div_close_menu div{
        cursor: pointer;
    }
    header .div_menu .mobile .div_purchase{
        width: 100%;
    }
    header .div_menu .mobile .div_img_user{
        width: 100%;
        padding-bottom: 22px;
        border-bottom: 1px solid #ffffff;
    }
    header .div_menu .mobile .div_purchase .show_purchase{
        position: fixed;
        right: 22px;
        left: inherit;
        top: inherit;
        bottom: 22px;
    }
    
    header .div_menu .div_inf_access{
        display: none;
    }
    header .div_menu .div_img_user .div_login_or_register .title{
        font-size: 14px;
        color: #ffffff;
    }
}

@media only screen and (max-width: 600px) {
    header .div_submenu {
        font-size: 12px;
    }
}

@media only screen and (max-width: 570px) {
    header .div_menu .show_data{
        justify-content: space-between;
    }
    header .div_menu .div_search{
        display: none;
    }
    header .div_menu{
        height: auto;
        padding: 20px 0px;
    }
    header .div_menu .div_search_mobile{
        position: relative;
        margin-top: 18px;
        display: block;
    }
    header .div_menu .div_search_mobile input{
        font-family: 'inter';
        outline: none;
        padding: 10px 46px 10px 10px;
        width: -webkit-fill-available;
    }
    header .div_menu .div_search_mobile .icons{
        width: 28px;
        height: auto;
        position: absolute;
        right: 10px;
        top: 6px;
    }
}

@media only screen and (max-width: 480px) {
    header .div_submenu {
        font-size: 10px;
    }
    header .div_menu .mobile .show_menu{
        width: 100%;
    }
    header .div_menu .mobile .div_purchase .show_purchase{
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
    }
    header .div_menu .div_purchase .show_purchase{
        width: 280px;
    }
}