.PopUp .ShowProduct{
    width: 600px;
}
.PopUp .ShowProduct .div_content{
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.PopUp .ShowProduct .div_content .div_title {
    width: 100%;
}
.PopUp .ShowProduct .div_content .div_title .title{
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #000000;
}
.PopUp .ShowProduct .div_content .div_title .subtitle{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}
.PopUp .ShowProduct .div_content .div_inf_product {
    width: 100%;
}
.PopUp .ShowProduct .div_content .div_inf_product .type_data{
    width: calc(100% - 40px);
    padding: 8px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #ffffff;
}
.PopUp .ShowProduct .div_content .div_inf_product .div_show_data{
    padding: 6px 20px;
    border: 1px solid;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.PopUp .ShowProduct .div_content .div_inf_product .div_show_product_{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.PopUp .ShowProduct .div_content .div_price_product{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.PopUp .ShowProduct .div_content .div_inf_product .price{
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
}
.PopUp .ShowProduct .div_content .div_inf_product .show_price{
    font-size: 14px;
    line-height: 14px;
}
.PopUp .ShowProduct .div_content .div_inf_product .discount_price{
    text-decoration: line-through
}
.PopUp .ShowProduct .div_content .div_inf_product .div_btn .btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 8px;
    cursor: pointer;
}

.PopUp .ShowProduct .div_content .div_gallery{
    width: 100%;
    position: relative;
    height: 200px;
}
.PopUp .ShowProduct .div_content .div_gallery .left{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.PopUp .ShowProduct .div_content .div_gallery .right{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.PopUp .ShowProduct .div_content .div_gallery .right .icons{
    transform: rotate(180deg);
    transition: .5s;
}
.PopUp .ShowProduct .div_content .div_gallery .show_img{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: inherit;
}