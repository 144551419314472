.Faq{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 60px 0px;
}
.Faq .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Faq .title{
    width: 100%;
    font-size: 46px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: -1px;
    text-align: center;
    padding-bottom: 30px;
}

.Faq .div_list_data{
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
.Faq .div_list_data .question{
    width: calc(100% - 22px);
    height: 40px;
    color: #ffffff;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #ffffff;
}
.Faq .div_list_data .question .icons{ 
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: .5s;
}
.Faq .div_list_data .question .icons_active{ 
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.Faq .div_list_data .response{ 
    max-height: 0;
    overflow-y: hidden;
    transition: max-height .5s ease-out;
    width: calc(100% - 22px);

    padding: 4px;
}

@media only screen and (max-width: 720px) {
    .Faq .container{
        gap: 0px;
    }
    .Faq .div_list_data{
        width: 100%;
    }
    .Faq .title{
        font-size: 32px;
        line-height: 32px;
    }
}
@media only screen and (max-width: 480px) {
    .Faq .title{
        font-size: 26px;
        line-height: 26px;
    }
}