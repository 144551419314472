.Banner{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Banner .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.Banner .two_banners{
    width: 48%;
    height: auto;
    max-height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
}
.Banner .one_banner{
    width: 100%;
    max-width: 1940px;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


@media only screen and (max-width: 1160px) {
    .Banner .container{
        gap: 20px;
    }
}
@media only screen and (max-width: 910px) {
}
@media only screen and (max-width: 750px) {
    .Banner .container{
        flex-direction: column;
    }
    .Banner .two_banners{
        width: 98%;
        max-height: inherit
    }
}
@media only screen and (max-width: 500px) {
}