.PopUp .ReturnResponse{
    width: 350px;
}
.PopUp .ReturnResponse .div_content{
    padding: 0px 20px;
    flex-direction: column;
}
.PopUp .ReturnResponse .icons{
    width: 170px;
    height: 170px;
    cursor: default;
}
.PopUp .ReturnResponse .title_return{
    font-size: 18px;
    font-family: 'Poppins';
    text-align: center;
}
.PopUp .ReturnResponse .btn{    
    color: #ffffff;
    width: calc(100% - 20px);
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    margin-top: 14px;
    border-radius: 6px;
    text-align: center;
}
.PopUp .ReturnResponse .close_ok{
    background-color: #76c57e;
}
.PopUp .ReturnResponse .close_error{
    background-color: #E1005A;
}