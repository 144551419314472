html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #2d3c53;
    font-family: 'Poppins';
    background-color: #ffffff;
    overflow-x: hidden;
    letter-spacing: 0px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
    list-style: disc !important;
    padding-left: 18px;
}
ul li {
    margin-bottom: 20px;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.space_div {
    flex-grow: 1;
}
.container{
    width: calc(100% - 60px);
    max-width: 1200px;
    margin: 0% auto;
    padding: 0px 30px;
}
@media only screen and (max-width: 580px) {
    .container{
        width: calc(100% - 30px);
        padding: 0px 15px;
    }
    .title_page{
        padding-bottom: 30px !important;
    }
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
::placeholder{
    color: rgb(212, 212, 212);
}
.show_data_page{
    width: 100%;
    height: auto;
    padding: 60px 0px;
}
.padding_0{
    padding: 0px;
}
.slick-initialized .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 630px) {
    .slick-list{
        width: 86% !important;
    }
}
@media only screen and (max-width: 534px) {
    .slick-list{
        width: 80% !important;
    }
}

/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #808FA3;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #ffffff;
}
/* end */


/* loading */
.return_data_save{
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 60%);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.div_loading .ld-ripple {
    position: relative;
    width: 80px;
    height: 80px;
}
.div_loading .ld-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ld-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.div_loading .ld-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes ld-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
/* end */


/* popUp */
.PopUp{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8888;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Inter';
    color: #6e6e6e;
    background-color: rgba(0, 0, 0, 50%);
}
.PopUpCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all{
    width: 600px;
    height: auto;
    margin: 2% auto;
    overflow-y: hidden;
    background: #ffffff;
    padding: 20px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
}
.PopUp .close_all{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
}

.PopUp .all .div_topic{
    width: calc(100% - 40px);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 10px;
    border-bottom: 1px solid #7e7a7a;
    font-family: 'Bebas Neue';
    font-size: 22px;
    line-height: 22px;
}

.PopUp .all .div_content{
    width: calc(100% - 40px);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 0px;
}
@media only screen and (max-width: 580px) {
    .PopUp .all{
        width: 90% !important;
    }
    .PopUp .all .title_return,
    .PopUp .all .btn{
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 400px) {
    .PopUp .all .div_content{
        flex-direction: column !important;
        gap: 10px !important;
    }
    .PopUp .all .btn{
        width: calc(100% - 20px) !important;
        border-radius: 6px !important;
    }
}
/* end */ 