.PopUp .Logar .div_content{
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.PopUp .Logar .div_content .div_input{ 
    width: 100%;
    margin-top: 10px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
}
.PopUp .Logar .div_content .div_input .two_input{
    width: 100%;
    position: relative;
}
.PopUp .Logar .div_content .div_input input{      
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.PopUp .Logar .div_content .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 10px;
    background: #ffffff;
    padding: 0px 10px;
}
.PopUp .Logar .div_content .div_input .cep{
    width: 110px;
}
.PopUp .Logar .div_content .div_input .cep input{
    text-align: center;
}
.PopUp .Logar .div_content .div_input .uf{
    width: 60px;
}
.PopUp .Logar .div_content .div_input .uf input{
    text-align: center;
}
.PopUp .Logar .div_content .div_input .contact{
    width: 160px;
}

.PopUp .Logar .div_content .btn{
    color: #ffffff;
    width: 100%;
    border: none;
    padding: 10px;
    font-family: 'inter';
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
}
.PopUp .Logar .div_content .error{
    color: #d95c5c;
    text-align: center;
}
.PopUp .Logar .div_purchase{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .Logar .div_purchase .btn{
    color: #ffffff;
    width: 100%;
    border: none;
    padding: 10px;
    font-family: 'inter';
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 660px) {
    .PopUpCenter{
        display: block;
    }
    .PopUp .Logar .div_content .div_input{
        flex-wrap: wrap;
    }
    .PopUp .Logar .div_content .div_input .two_input{
    }
    .PopUp .Logar .div_content .div_input .cep{
        width: inherit;
    }
    .PopUp .Logar .div_content .div_input .uf{
        width: inherit;
    }
    .PopUp .Logar .div_content .div_input .contact{
        width: inherit;
    }
}