.Btn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    color: #ffffff;
}
.Btn .container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.Btn .show_btn{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 30px;
    flex-grow: 1;
}
.Btn .show_btn .div_icon{
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Btn .show_btn .div_icon .icons{
    width: auto;
    height: 100%;
    display: flex;
}
.Btn .show_btn .text{
    font-size: 16px;
    line-height: 16px;
}
.Btn .show_btn .title{
    font-weight: 700;
}
.Btn .show_btn .subtitle{
    font-weight: 400;
}

@media only screen and (max-width: 920px) {
    .Btn .show_btn{
        padding: 20px 10px;
    }
}
@media only screen and (max-width: 850px) {
    .Btn .container {
        flex-direction: column;
        gap: 10px;
    }
}